import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

/* Image imports - likely need to use PNGs. */


/* need contact form that pushes to a mail service */
const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <p align="center">Coming Soon!</p>
  </Layout>
)

export default ContactPage
